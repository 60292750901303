
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { LineLoader } from '@apps-orangefi/ui/atoms'
import { getLayout } from '@apps-orangefi/ui/layouts'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { NextPageWithLayout } from '@/pages/_app'

const Products: NextPageWithLayout = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace('/arbitrum')
  }, [router])
  return (
    <div className="container w-full h-full flex flex-col justify-center items-center">
      <LineLoader />
    </div>
  )
}
Products.getLayout = getLayout

export default Products


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  